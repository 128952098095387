import { useApolloClient } from "@apollo/client";
import "./editPackageDetailsModal.scss";
import { pdfjs } from "react-pdf";

import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { GET_FILE_BASE64, GET_FILE_DOWNLOAD_LINK } from "../../queries/vip-workflow";
import downloadIcon from "../../assets/img/icons/cards/download-icon.svg";
import qrCodeIcon from "../../assets/img/icons/cards/qr-code-icon.svg";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const UploadedTickets = ({
  ticket,
  index,
  setFile,
}: any) => {
  const client = useApolloClient();

  return (
    <div
        className="d-flex justify-content-between"
        key={index}
    >
        <div className="d-flex align-items-center" style={{ width: "100%" }}>
            <button 
                type="button"
                style={{ cursor: 'pointer' }}
                className="btn btn-link p-0"
                onClick={async () => {
                    const { data } = await client.query({
                        query: GET_FILE_DOWNLOAD_LINK,
                        variables: { id: ticket.id },
                        fetchPolicy: "no-cache",
                    });

                    const link = document.createElement("a");
                    link.href = data?.getFileDownloadLink;
                    link.download = ticket.key;

                    document.body.appendChild(link);
                    link.click();
                    //@ts-expect-error
                    link.parentNode.removeChild(link);
                }}
            >
                {ticket.isQrCode
                    ? <img src={qrCodeIcon} alt="qr-icon" style={{ cursor: 'pointer' }}/>
                    : <img src={downloadIcon} alt="download-icon" style={{ cursor: 'pointer' }}/>
                } 
            </button>

            <button
                type="button"
                className="btn btn-link p-2"
                style={{ overflowWrap: 'anywhere', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                onClick={async () => {
                    const { data } = await client.query({
                    query: GET_FILE_BASE64,
                    variables: { id: ticket.id },
                    fetchPolicy: "no-cache",
                    });
                    setFile(data?.getFile);
                }}
            >
               {ticket.isQrCode 
                ? <>Open QR Code</>
                : <>View Tickets</>
               }
             {/* {ticket.fileName
                ? ticket.fileName
                : ticket.key?.replace(/[0-9]*-/, "")} */}
            </button>
        </div>
    </div>
  );
};

export default UploadedTickets;
